// Header
.header {
	position: sticky;
	@include media-breakpoint-up(lg) { 
		top: 0;
		z-index: 10000;
	}
	.header-top {
		font-size: 0.875rem;
		padding: 0.6rem;
		background: $primary;
		color: $white;
		a {
			color: $white;
			&:hover {
				color: $black;
			}
		}
		.social-icons {
			font-size: 2rem;
			a + a {
				margin-left: 0.6rem;
			}
		}
		.header-logo {
			img {
				height: 2.6rem;
			}
		}
	}
}

.header-slider {
	position: relative;
	margin: 0;
	border: 0;
	height: 17rem;
	@include media-breakpoint-up(md) { 
		height: 20rem;
	}
	@include media-breakpoint-up(lg) { 
		height: 30rem;
	}
	@include media-breakpoint-up(xl) { 
		height: 40rem;
	}	
	.flex-direction-nav a {
		z-index: 1000;
		display: block;
		width: 3rem;
		background-color: transparent;
		opacity: 0.7 !important;
		line-height: 1;
		@include media-breakpoint-up(lg) { 
			font-size: 3rem;
			&:before {
				font-size: 4rem;
			}
		}
	}
	ul,
	li {
		position: absolute !important;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.header-image {
	position: relative;
	p {
		margin: 0;
		color: $white;
		text-shadow: 0 0.15rem 0.25rem rgba($black, 0.6);
		font-weight: $font-weight-bold;
		font-size: 1.2rem;
		@include media-breakpoint-up(md) { 
			font-size: 1.8rem;
		}
		@include media-breakpoint-up(lg) { 
			font-size: 2rem;
		}
		@include media-breakpoint-up(xl) { 
			font-size: 2.5rem;
		}
	}
	.header-logo img {
		width: 100%;
		filter: drop-shadow(0 0.4rem 0.4rem rgba($black, 0.8));
	}
}