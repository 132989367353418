$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #48494A;
$black:    #000;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);

$light : $white;

$blue:  #00B3DF;

$gray:		#807F80;
$white:		#fff;

$primary: 		$blue;
$secondary: 	$black;
$tertiary:   $gray;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "tertiary":   $tertiary
);

$body-color:                $black;

// Spacing
$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 4.5)
);

// Options
$enable-rounded:	false;
$enable-caret:              false;

//Fonts
$primary-font-family: 		'Open Sans', sans-serif;
$secondary-font-family: 	$primary-font-family;
$font-family-base: 			$primary-font-family;
$headings-font-family: 		$secondary-font-family;


$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            700;

$btn-font-weight:             $font-weight-bold;

$input-btn-padding-y:         .5rem;
$input-btn-padding-x:         .75rem;

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               1.5rem;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            0.75rem;

$headings-font-weight: 	    	$font-weight-bold;

$line-height-lg:              1.8;
$line-height-sm:              1.2;

$box-shadow:                  0 .5rem 1rem rgba($black, .5);

// Navs
$navbar-padding-y:                  $spacer;

$navbar-dark-color:                 rgba($white, 1);
$navbar-dark-hover-color:           $primary;
$navbar-dark-active-color:          $primary;

// Table 
$table-accent-bg:                   $gray-200;

// Dropdowns
$dropdown-bg:                       $primary;
$dropdown-border-color:             $primary;

$dropdown-link-color:               rgba($white, 1);
$dropdown-link-hover-color:         rgba($white, 0.8);
$dropdown-link-hover-bg:            transparent;

$dropdown-link-active-color:        rgba($white, 1);
$dropdown-link-active-bg:           transparent;

$dropdown-item-padding-y:           .25rem;
$dropdown-item-padding-x:           1rem;
