//Sidebar
.widget-area {
	.widget-title {
		font-size: 1.2rem;
	}
	.widget {
		background: lighten($primary , 50%);
	}	
	.widget-content {
		padding: 1.25rem;

	}
	p {
		margin: 0;
	}
	ul {
		@extend .liststyle-none;
	}
	label {
		display: block;
	}
	select {
		min-width: 100%;
	}
	.searchandfilter {
		.search-filter-reset {
		    @extend .btn;
		    @extend .btn-primary;
		}		
	}
}