img, 
video {
	max-width: 100%;
	height: auto;	
}

h1 {
	font-size: 1.7rem;
}

h2 {
	font-size: 1.6rem;
}

h3 {
	font-size: 1.4rem;
}

h1, h2, h3 {
	text-transform: uppercase;
}


%wrapper-bg {
	position: relative;
	&::after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.wrapper-bg-1 {
	@extend %wrapper-bg;
	&::after {
		opacity: 0.8;
		background: url("../img/wrapper-bg-1.svg");
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;		
	}
}
.wrapper-bg-2 {
	@extend %wrapper-bg;
	&::after {
		background: url("../img/wrapper-bg-2.jpg");
		background-position: top;
		background-repeat: no-repeat;
		background-size: cover;				
	}
}

.wrapper-bg-3 {
	@extend %wrapper-bg;
	&::after {
		background: url("../img/wrapper-bg-3.jpg");
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: cover;			
	}
}

.page-numbers {
	@extend .btn;
	@extend .btn-secondary;
}

.page-numbers.current {
	@extend .btn-primary;
}

textarea {
	@extend .form-control;
}

label {
    cursor: default;
}

.page-header  {
	margin-bottom: ($spacer * 1.5);
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
	> .col,
	> [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}

.sm-gutters {
	margin-right: -0.5rem;
	margin-left: -0.5rem;
	> .col,
	> [class*="col-"] {
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}
}

.liststyle-none {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

input[type="email"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="week"],
input[type="url"],
select,
textarea {
    @extend .form-control;
}

input[type="submit"] {
    @extend .btn;
    @extend .btn-primary;
}

.nav-pills {
	border-bottom: 1px solid $secondary;
	.nav-link {
		margin-bottom: -1px;
		border: solid transparent;
		border-width: 1px;
		color: $secondary;
		font-weight: $font-weight-bold;

	}
	.nav-link.active,
	.show > .nav-link {
		border: solid $secondary;
		border-width: 1px;
		border-bottom-color: $white;
		background-color: transparent;
		color: $secondary;
	}
}

.breadcrumb {
	padding: 1rem;
	font-size: 1rem;
	font-family: $secondary-font-family;
	color: $black;
}

.blockquote,
blockquote {
	@extend .blockquote;
	padding: 1rem;
	font-size: 1rem;
	color: $dark;
	border: 1px solid theme-color-level(primary, -8);
	background: theme-color-level(primary, -10);
	p {
		margin: 0;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

// Flexible content
.card {
	border: none;
	border-radius: 0;
}

.card-header {
	padding: 1rem;
	margin-bottom: 0;
	h5 {
		font-size: 1rem;
		color: $dark;
		a {
			cursor: pointer;
			.card-icon {
				width: 2rem;
				height: 2rem;
				border-radius: 50%;
				background: $primary;
				color: #fff;
				text-align: center;
				font-size: 1.4rem;
			}
			@include hover-focus {
				.card-icon {
					text-decoration: none;
				}
			}
		}
		a[aria-expanded="true"] .card-icon i {
			transform: rotate(-180deg);
 			transition: all linear 0.25s;
		}
		a[aria-expanded="false"] .card-icon i {
			transition: all linear 0.25s;
		}
	}
}

img.alignright { 
	float: right; 
	margin: 0 0 0.5rem 0.5rem; 
}
img.alignleft { 
	float: left; 
	margin: 0 0.5rem 0.5rem 0; 
}
img.aligncenter { 
	display: block; 
	margin-left: auto; 
	margin-right: auto; 
}

.alignright { 
	float: right; 
}
.alignleft { 
	float: left; 
}
.aligncenter { 
	display: block;
	margin-left: auto; 
	margin-right: auto; 
}