body {
    .gform_wrapper {
        margin: 0;
        form {
            margin-bottom: 0;
        }
        ul {
            @extend .list-unstyled;
        }
        li {
            @extend .form-group;
        }
        ul.gform_fields li.gfield {
            margin-top: 0;
        }
        .gfield_required {
            padding-left: 1px;
            color: $primary;
        }
        input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
        textarea,
        select {
            @extend .form-control;
        }
        input.ginput_quantity[type=text] {
            width: 3rem !important;
            display: inline !important;
        }    
        ul.gfield_checkbox li input[type=checkbox], 
        ul.gfield_radio li input[type=radio] {
            margin: 0 !important;
        }
        .button {
            @extend .btn;
            @extend .btn-primary;
        }
        .button.gform_button_select_files {
            @extend .btn-sm;
        }
        div.validation_error,
        li.gfield.gfield_error {
            border: none;
            @extend .alert-danger;
        }
        div.validation_error {
            @extend .alert;
        }
        .validation_message {
            display: none;
        }
        .gform_footer {
            margin: 0;
            padding: 0;
        }
        .gf_offpage {
           position: absolute !important;
           top: -9999rem !important;
           left: -9999rem !important;
           visibility: hidden;
        }    
        span.ginput_product_price {
            color: $primary;
        }
        input.ginput_quantity[type=text] {
            display: inline-block;
        }
        span.ginput_total {
            color: $secondary;
        }
        ul li.field_description_below div.ginput_container_radio, 
        ul li.field_description_below div.ginput_container_checkbox {
            margin: 0 !important;
        }
        .gform_page_footer {
            border-top: 0;
        }
    }
    .gpnf-nested-entries {
        width: 100%;
        tr {
            th {
                background: $primary;
                color: $light;
            }
            th, td {
                padding: 0.5rem;
                border-bottom: 1px solid $gray-200;
            }
        }
    }
    .gpnf-add-entry {
        @extend .btn;
        @extend .btn-outline-secondary;        
    }
    .gpnf-dialog {
        .ui-icon::after {
            opacity: 0.9;
            font-size: 1.5rem;
        }
        .ui-dialog-titlebar {
            background: $primary !important;
        }        
        .ui-dialog-content {
            border: none;
            padding: 1.2rem;
        }       
    }    
}