// Use this file to overwrite the basic Bootstrap variables and add your own variables
$orange:  #FE6612;
$gray:		#807F80;
$black:    #000;

$primary: 		$orange;
$secondary: 	$black;
$tertiary:   $gray;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "tertiary":   $tertiary
);

// Navs
$navbar-dark-hover-color:           $primary;
$navbar-dark-active-color:          $primary;

// Dropdowns
$dropdown-bg:                       $primary;
$dropdown-border-color:             $primary;