@import "theme/theme_variables";
@import "theme/theme_variables-2";
@import "assets/bootstrap4/bootstrap";
@import "assets/fontawesome/font-awesome";

@import "assets/flexslider/flexslider";
@import "assets/swipebox/swipebox";

@import "theme/theme"; 
@import "theme/header"; 
@import "theme/navigation"; 
@import "theme/page"; 
@import "theme/widgets"; 
@import "theme/footer"; 
@import "theme/gf"; 


.navbar {
	.navbar-nav {
		@include media-breakpoint-up(lg) {
			li {
				.nav-link {
					font-size: 1rem;
				}
			}
		}	
	}
}

%wrapper-bg {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
}

.wrapper-bg-1 {
	@extend %wrapper-bg;
	&:after {
		opacity: 0.2;
		background: url("../img/2/wrapper-bg-1.svg");
		background-position: 50% 70%;
		background-repeat: no-repeat;
		background-size: cover;		
	}
}
.wrapper-bg-2 {
	@extend %wrapper-bg;
	&:after {
		background: url("../img/2/wrapper-bg-2.jpg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;				
	}
}

.wrapper-bg-3 {
	@extend %wrapper-bg;
	&:after {
		background: url("../img/2/wrapper-bg-2.jpg");
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;			
	}
}

.blokken {
	.blokken-container {
		@extend %wrapper-bg;
		&:after {
			z-index: 0;
			opacity: 0.2;
			background: url("../img/2/wrapper-bg-1.svg");
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;					
		}
	}
}