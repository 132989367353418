.front {
	.btns {
		.btn {
			font-size: 1rem;
			text-align: center;
			display: block;
			color: $white;
			padding: 0.8rem;
			border: 0.2rem solid $white;
			filter: drop-shadow(0 0.4rem 0.4rem rgba($black, 0.8));
			@include media-breakpoint-up(md) { 
				padding: 1.5rem;
				font-size: 2rem;
			}
		}
		@include media-breakpoint-down(md) { 
			.col:nth-child(odd) .btn {
				background: $secondary;
				&:hover {
					background: $tertiary;
				}		
			}
			.col:nth-child(even) .btn {
				background: $primary;
				&:hover {
					background: $tertiary;
				}		
			}
		}
		@include media-breakpoint-up(md) { 
			.col:nth-child(4n+1) .btn,
			.col:nth-child(4n+4) .btn {
				background: $secondary;
				&:hover {
					background: $tertiary;
				}		
			}
			.col:nth-child(4n+2) .btn,
			.col:nth-child(4n+3) .btn {
				background: $primary;
				&:hover {
					background: $tertiary;
				}		
			}
		}
	}
	.intros {
		.intro {
			font-size: 0.875rem;
			.intro-title {
				height: 3rem;
				overflow: hidden;
				text-transform: uppercase;
				font-size: 1.25rem;
				a {
					color: $white;
					text-decoration: none;
				}
			}
			.intro-subtitle {
				margin-bottom: 0.5rem;
				text-transform: uppercase;
				font-weight: $font-weight-bold;
			}			
			p {
				margin: 0;
			}
			.intro-more {
				font-size: 0.875rem;
				color: $white;
				font-weight: $font-weight-bold;
				text-transform: uppercase;
			}			
		}
	}
}


// Aanbod
.aanbod { 
	.sum {
		position: relative;
		width: 100%;
		&::after {
			content: "";
			display: block;
			padding-bottom: 80%;
		}
		.sum-data,
		.sum-meta {
			background: $primary;
		}
		.sum-data {
			width: 100%;
			padding: 1rem;
			position: absolute;
			bottom: 0;
			.sum-title { 
				display: block;
				overflow: hidden;
				margin: 0;
				height: 2.75rem;
				color: $white;
				font-size: 1.2rem;
			}
			.sum-date { 
				font-weight: 300;
				font-size: 18px;
				color: $white;	
			}
		}
		.sum-meta {
			position: absolute;
			top: 0;
			bottom: 0;
			visibility: hidden;
			width: 100%;
			color: $white;
			font-weight: 300;
			font-size: 1rem;
			opacity: 0;
			@include transition(visibility 0s, opacity 0.3s linear);
			.meta-content {
				position: absolute;
				top: 50%;
				left: 50%;
				padding: 1.25rem;
				width: 100%;
				text-align: center;
				transform: translate(-50%, -50%);
			}
		}			
		ul {
			@extend .liststyle-none;
		}
		&:hover {
			.sum-meta {
				visibility: visible;
				opacity: 1;
				.meta-title {
					color: $white;
					text-transform: uppercase;
					font-size: 1.3rem;
				}
			}
		}				
	}
}

.aanbod {
	.single-thumbnail {
		height: 20rem;
		@include media-breakpoint-up(lg) { 
			min-height: 22rem;
			height: 100%;
		}
	}
	.single-data {
		ul {
			@extend .liststyle-none;
			li {

			}
		}
	}
	.custom-header {
		background-image: linear-gradient(180deg, $light 30%, $primary 100%);
		.single-title {
			color: $light;
			text-transform: none;
			@include media-breakpoint-up(lg) { 
				font-size: 2.5rem;
			}	
		}
		.item {
			text-align: center;
			font-size: 1.2rem;
			background: gray("900");
			line-height: $line-height-sm;
			@include hover-focus {
				text-decoration: none;
				background: darken(gray("900"), 10%);
			}
			@include media-breakpoint-up(lg) { 
				font-size: 2rem;
			}
			a {
				color: $light;
				@include hover-focus {
					text-decoration: none;
					color: $light;
				}
			}
			@include media-breakpoint-up(lg) { 
				&:before,
				&:after {
					content: '“';
					position: absolute;
					width: 3.4rem;
					height: 3.4rem;
					line-height: 4rem;
					color: $dark;
					font-size: 3rem;
					background: $light;
					border: 3px solid $primary;	

				}
				&:before {
					top: -1rem;
					left: -1rem;
				}
				&:after {
					bottom: -1rem;
					right: -1rem;
				}
			}
		}
	}
}

.docent {
	.sum {
		.sum-thumbnail {
		}
		.sum-title {
			font-size: 1.2rem;
		}
		.sum-excerpt {
			font-size: 0.875rem;
		}		
	}
}

.page .site-main,
.single .site-main {
	.entry {
		h2 {
			font-size: 1.6rem;
		}
		h3 {
			font-size: 1.3rem;
		}
		position: relative;
		z-index: 0;
		padding: 1rem;
		@include media-breakpoint-up(md) {
			padding: 2rem;
		}
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 5rem;
		}
	}
}

.post .entry {
	background: $white;
	.entry-title {
		a {
			color: $black;
			text-decoration: none;
		}		
	}
	.entry-content {
		padding: 2rem;
	}
	.entry-meta {
		font-weight: $font-weight-normal;
		font-size: 0.875rem;
	}
}

.vacature .entry {
	.entry-title {
		font-size: 1.5rem;
		a {
			color: $secondary;
			&:hover {
				text-decoration: none;
			}
		}
	}
	ul.entry-meta {
		@extend .liststyle-none;
		li {
			float: left;
			font-size: 0.875rem;
			& + li {
				&:before {
					content: "|";
					margin: 0 0.25rem;
					position: relative;
					float: left;
				}
			}
		}
	}
}

.review .entry {
	background: $white;
	.entry-title {
		font-size: 1.5rem;
		a {
			color: $black;
			text-decoration: none;
		}		
	}
	ul.entry-meta {
		@extend .liststyle-none;
		li {
			float: left;
			font-size: 0.875rem;
			color: $primary;
			font-weight: $font-weight-bold;
			& + li {
				&:before {
					content: "|";
					margin: 0 0.25rem;
					position: relative;
					float: left;
				}
			}
		}
	}
	.entry-rating {
		width: 3.2rem;
		height: 3.2rem;
		background: $primary;
		color: $light;
		border-radius: 50%;
		font-size: 1.4rem;
		font-weight: $font-weight-bold;
		span {
			font-size: 1rem;
		}
	}
	.entry-meta {

	}
}

.blokken {
	.blokken-container {
		border: solid $white;
		border-width: 0.3rem 0.3rem 0 0.3rem;
		@extend %wrapper-bg;
		&::after {
			z-index: 0;
			opacity: 0.8;
			background: url("../img/wrapper-bg-1.svg");
			background-position: top;
			background-repeat: no-repeat;
			background-size: cover;				
		}
		.intro {
			position: relative;
			z-index: 1;
			border-bottom: 0.3rem solid $white;
			.intro-title {
				font-size: 1.5rem;
			}
			@include media-breakpoint-up(md) {
				&.col-lg-4 {
					&:nth-child(3n+2) {
						border-left: 0.3rem solid $white;
						border-right: 0.3rem solid $white;
					}
				}				
				&.col-lg-6 {
					&:nth-child(even) {
						border-left: 0.3rem solid $white;
					}
				}
			}
		}
	}
}

.page-gallery {
	.meta-description {
		text-transform: none;
	}
}

// Filters
.grid.aanbod .element-item:nth-child(3n+2) .sum-data ,
.grid.aanbod .element-item:nth-child(3n+2) .sum-meta  {
	background: $secondary;
}

.grid.aanbod .element-item:nth-child(3n+3) .sum-data,
.grid.aanbod .element-item:nth-child(3n+3) .sum-meta   {
	background: $tertiary;
}

.filters-button-group {
	a {
		@extend .btn;
		@extend .btn-outline-tertiary;
		@extend .btn-lg;
		background: $white;
		color: $tertiary;
		&:first-child {
			margin-left: 0;
		}
		+ a {
			margin-left: 0.5rem;		
		}
		&:hover,
		&.is-checked,
		&.is-checked:hover {
			@extend .btn-secondary;		
			color: $white !important;
		}
	}
}


.grid-item {
	.element {
		.entry {
			padding: 0  1rem;
			height: 5.5rem;
			@include transition($btn-transition);
			.entry-title {
				margin: 0;
				color: $white;
				font-size: 1.125rem;
				font-family: $primary-font-family;
			}
			.entry-more {
				i {
					color: $white;
					font-size: 3rem;
					padding-left: 0.5rem;
				}
			}
		}
	}
	&:nth-child(odd) .entry {
		background: $primary;

	}
	&:nth-child(even) .entry {
		background: $secondary;	
	}
	&:hover .entry {
		background: $tertiary !important;
		h3.entry-title {
			text-decoration: none;
		}
	}		
	a:hover {
		text-decoration: none;
	}
}

.gutter-sizer {
	z-index: -10;
}