.site-footer {
	background: $secondary;
	color: color-yiq($secondary);
	font-size: 0.875rem;	
	a {
		color: color-yiq($secondary);
	}
	.footer-area {
		.widget-title {
			text-align: left;
			font-size: 1.2rem;
			color: $primary;
			text-transform: none;
		}
		.widget {
			line-height: 1.8;
		}
		ul {
			@extend .liststyle-none;
			li {
				margin-bottom: 0.4rem;
				i {
					text-align: right;
					font-size: 1.1rem;
					padding-right: 0.4rem;
					width: 1.3rem;				
				}
			}
		}		
	}
	.footer-top {
		h3 {
			color: color-yiq($primary);
			font-weight: $font-weight-normal;
			font-size: 1.3rem;
		}
		p {
			margin: 0;
		}
	}
	.footer-bottom {
		background: $black;
	}
}