/*!
 * jQuery FlexSlider v2.7.2
 * https://www.woocommerce.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */

/* Resets */
.flex-container a:hover,
.flex-slider a:hover {
    outline: none;
}

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
    margin: 0;
    padding: 0;
    list-style: none;
}

.flex-pauseplay span {
    text-transform: capitalize;
}


/* Base styles */
.flexslider {
    margin: 0;
    padding: 0;
    position: relative;
    .slides > li {
        display: none;
        margin: 0;
        padding: 0;
        -webkit-backface-visibility: hidden;
        img {
            width: 100%;
            display: block;
        }   
        &:after {
            content: "\0020";
            display: block;
            clear: both;
            visibility: hidden;
            line-height: 0;
            height: 0;         
        } 
    }
}

html[xmlns] .flexslider .slides {
    display: block;
}

* html .flexslider .slides {
    height: 1%;
}

.no-js .flexslider .slides > li:first-child {
    display: block;
}


/* Default theme */
.flexslider .slides {
    zoom: 1;
    img {
        height: auto;
        -moz-user-select: none;
    }
}

.flex-viewport {
    max-height: 2000px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.loading .flex-viewport {
    max-height: 300px;
}

.carousel li {
    margin-right: 5px;
}

.flex-direction-nav {
    *height: 0;
    a {
        text-decoration: none;
        display: block;
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: calc(50% - 1.5rem);
        z-index: 10;
        overflow: hidden;
        opacity: 0;
        cursor: pointer;
        color: $white;
        background: $secondary;
        transition: all 0.3s ease-in-out;
        &:hover {
            background: darken($secondary , 10%);
        }
    }
    .flex-prev,
    .flex-next {
        text-align: center;
        opacity: 1;
        &:before {
            line-height: 3rem;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 2rem;
            display: inline-block;
            color: $white;
            line-height: 3rem;
        }         
    }
    .flex-prev {
        left: 0;
        &::before {
            content: '\f104';            
        }         
    }    
    .flex-next {
        right: 0;
        &::before {
            content: '\f105';            
        }       
    }
    .flex-disabled {
        opacity: 0!important;
        filter: alpha(opacity=0);
        cursor: default;
        z-index: -1;
    }    
}

.flex-pauseplay a {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    bottom: 0.3rem;
    left: 0.6rem;
    opacity: 0.8;
    z-index: 10;
    overflow: hidden;
    cursor: pointer;
    color: $black;
}

.flex-pauseplay {
    a:before {
        font-family: "flexslider-icon";
        font-size: 1.25rem;
        display: inline-block;
        content: '\f004';
    }
    a:hover {
        opacity: 1;
    }
    a.flex-play:before {
        content: '\f003';
    }    
}

.flex-control-nav {
    width: 100%;
    position: absolute;
    z-index: 5;
    bottom: 0.6rem;
    text-align: center;
    li {
      margin: 0 0.4rem;
      display: inline-block;
      zoom: 1;
      *display: inline;
    }
}

.flex-control-paging li {
    a {
        width: 1rem;
        height: 1rem;
        display: block;
        border: 1px solid $white;
        background: transparent;
        cursor: pointer;
        text-indent: -9999px;
        border-radius: 50%;
        &:hover {
            background: $white;
        }
    }
    a.flex-active {
        background: $white;
        cursor: default;
    }    
}

.flex-control-thumbs {
    margin: 0.3rem 0 0;
    position: static;
    overflow: hidden;
    li {
        width: 25%;
        float: left;
        margin: 0;
    }
    img {
        width: 100%;
        height: auto;
        display: block;
        opacity: .7;
        cursor: pointer;
        -moz-user-select: none;
        transition: all 1s ease;
        &:hover {
            opacity: 1;
        }
    }
    .flex-active-slide img {
        opacity: 1;
        cursor: default;
    }
}


/* Custom */
#header-slider {
    .flex-prev {
        left: 0;
    }
    .flex-next {
        right: 0;
    } 
}

