.dropdown:hover > .dropdown-menu {
	display: block;
}

.navbar {
	padding: 0.5rem 1rem;
	@include media-breakpoint-up(lg) {
		padding: 0;
	}
	.navbar-nav {
		@include media-breakpoint-up(lg) {
			li {
				.nav-link {
					font-size: 1.1rem;
					text-transform: uppercase;
					padding: 1.2rem 0.5rem;
				}
			}
		}			
		.dropdown-menu {
			border: none;
			text-transform: none;
			margin-top: 0;	
			border-radius: 0;
			padding: 0.5rem 0;
			li {
				margin: 0;
				.nav-link {
					text-transform: none;
				}
			}
			.dropdown-menu {
				top: -0.5rem;				
				left: 100%;
			}
		}			
	}
}